import React from 'react';
import {ListItem, ListItemText} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import Stack from "@mui/material/Stack";

type Props = {
	name: string;
	date: string;
	type: 't' | 'c';
	handleThumbnailOnOff(onOff: boolean, type: 't' | 'c'): void;
	handleImageDel(e: any, type: 't' | 'c'): void;
}

function ImageItem({ name, date, type, handleThumbnailOnOff,handleImageDel }: Props) {
	return (
		<Stack
			direction="row"
			justifyContent="space-between"
			alignItems="center"
			spacing={2}
			style={{
				border: '1px solid rgba(145, 158, 171, 0.24)',
				borderRadius: 16,
				padding: 16,
				cursor: 'pointer'
			}}
			sx={{
				'&:hover': {
					bgcolor: '#efefef',
				},
			}}
			onClick={() => handleThumbnailOnOff(true, type)}
		>
			<img src="/assets/icons/ic_img.svg" alt=""/>
			<ListItem>
				<ListItemText
					primary={`${type === 'c' ? '컨텐츠 이미지' : '썸네일 이미지'} (${name})`}
					secondary={date}
				/>
			</ListItem>
			<IconButton
				aria-label="delete"
				onClick={(e) => handleImageDel(e, type)}
			>
				<DeleteIcon />
			</IconButton>
		</Stack>
	);
}

export default ImageItem;