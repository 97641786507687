import React, {useCallback} from 'react';
import Button from "@mui/material/Button";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import Stack from "@mui/material/Stack";
import {useRouter} from "../../hooks";

function BackButton() {
	const { back } = useRouter();
	const handleBack = useCallback(() => {
		back();
	}, []);
	return (
		<Stack
			direction="row"
			justifyContent="space-between"
			alignItems="center"
			spacing={1}
			mb={4}
		>
			<Button
				size="large"
				sx={{
					color: '#000000',
					fontSize: 16,
					display: 'flow'
				}}
				onClick={handleBack}
			>
				<ArrowBackIosNewIcon sx={{mr: 1, fontSize: 12}}/>
				Back
			</Button>
		</Stack>
	);
}

export default BackButton;