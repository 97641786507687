import { useState, useCallback } from 'react';

export default function useInput(defaultValue : any) {
	const [input, setInput] = useState(defaultValue);

	const onChange = useCallback((e : any) => {
		if (e?.target) setInput(e.target.value);
		else setInput(e);
	}, []);
	const onReset = useCallback(() : void => setInput(""), []);
	return [input, onChange, onReset]
}