import React, {useState} from 'react';

import Box from '@mui/material/Box';

import Nav from './nav';
import Header from './header';
import Main from "./main";

type Props = {
	children: React.JSX.Element
}
function DashboardLayout({children}: Props) {
	const [openNav, setOpenNav] = useState<boolean>(false);

	return (
		<>
			<Header onOpenNav={() => setOpenNav(true)} />
			<Box
				sx={{
					minHeight: 1,
					display: 'flex',
					flexDirection: { xs: 'column', lg: 'row' },
				}}
			>
				<Nav openNav={openNav} onCloseNav={() => setOpenNav(false)} />

				<Main>{children}</Main>
			</Box>
		</>
	);
}

export default DashboardLayout;