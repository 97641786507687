import {SvgColor} from "../../components/commons/svgColor";
import React from "react";

const icon = (name: string) => (
	<SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const navConfig: {title: string, path: string, icon?: React.JSX.Element}[] = [
	{
		title: '사용자 관리',
		path: '/user',
		icon: undefined, // icon('ic_analytics'),
	},
	{
		title: '상풍 구매 관리',
		path: '/produce',
		icon: undefined, //icon('ic_user'),
	},
	{
		title: '하트 지급',
		path: '/store',
		icon: undefined, // icon('ic_cart'),
	},
	{
		title: '푸쉬 관리',
		path: '/push',
		icon: undefined, // icon('ic_blog'),
	},
	{
		title: '컨텐츠',
		path: '/news',
		icon: undefined, // icon('ic_blog'),
	},
	// {
	// 	title: 'login',
	// 	path: '/login',
	// 	icon: icon('ic_lock'),
	// },
	// {
	// 	title: 'Not found',
	// 	path: '/404',
	// 	icon: icon('ic_disabled'),
	// },
];

export default navConfig;