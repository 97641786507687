import {
	getDatabase,
	ref,
	push,
	set,
	get,
	child,
	update,
	remove,
} from "firebase/database"
import { ContentItem } from "../types/TypeFirebaseContent"

export class ContentRepository {
	public saveContent = (item: ContentItem) => {
		const db = getDatabase()

		const newPostKey = push(child(ref(db), `contents`)).key

		return set(ref(db, `contents/${newPostKey}`), item)
	}

	public updateContent = (id: string, item: ContentItem) => {
		const db = getDatabase()
		const updates: any = {}
		updates[`contents/${id}`] = item
		return update(ref(db), updates)
	}

	public deleteContent = (id: string) => {
		const db = getDatabase();

		return remove(ref(db,  `contents/${id}`));
	}

	public getContentList = async (): Promise<ContentItem[]> => {
		const dbRef = ref(getDatabase())

		return get(child(dbRef, `contents`))
		.then((snapshot) => {
			if (snapshot.exists()) {
				return snapshot.val() as { [key: string]: ContentItem }
			}

			throw new Error("Unavailable")
		})
		.then((result) => {
			return Object.keys(result).map((key) => ({
				id: key,
				...result[key],
			}))
		})
		.catch((ex) => {
			return []
		})
	}
}
