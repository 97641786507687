import client from './client';
import {TypeUserLoginReq} from "../../types/TypeUser";

export const loginAPI = (data: TypeUserLoginReq) => (
	client({
		url: '/v1/admin/login',
		data,
		isAuth: false,
		withCredentials: false,
		method:'post'
	})
);

export const loginInfo = () => (
	client({
		url: '/v1/admin/test'
	})
);