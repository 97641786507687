import React, {useEffect} from 'react';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import {alpha} from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import ListItemButton from '@mui/material/ListItemButton';

import {usePathname} from "../../hooks/usePathname";
import {useResponsive} from "../../hooks/useReponsive";
import { NAV } from './configLayout';
import RouterLink from '../../routes/routerLink';
import Logo from '../../components/commons/logo';
import Scrollbar from '../../components/commons/scrollbar/Scrollbar';
import navConfig from "./configNavigation";
import {Grid} from "@mui/material";
import {useLogin} from "../../hooks";
import {wesee} from "../../theme/palette";
import {typography} from "../../theme/typography";


type Props = {
	openNav: boolean;
	onCloseNav(): void;
}

export default function Nav({openNav, onCloseNav}: Props) {
	const pathname = usePathname();
	const upLg = useResponsive({query: 'up', start: 'lg'});
	const tmaEmail = window.localStorage.getItem('tma_email');
	const { userLogout } = useLogin();

	useEffect(() => {
		if (!openNav) return;

		onCloseNav();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pathname]);

	const renderAccount = (
		<Box
			sx={{
				my: 3,
				mx: 2.5,
				py: 2,
				px: 2.5,
				display: 'flex',
				borderRadius: 1.5,
				alignItems: 'center',
				bgcolor: (theme) => alpha(theme.palette.grey[500], 0.12),
			}}
		>
			<Avatar src='/assets/icons/app_icon.png' alt="photoURL"/>

			<Box sx={{ml: 2}}>
				<Typography variant="subtitle2">관리자</Typography>

				<Typography variant="body2" sx={{color: 'text.secondary'}}>
					{tmaEmail}
				</Typography>
			</Box>
		</Box>
	);

	const renderMenu = (
		<Stack component="nav" spacing={0.5} sx={{px: 2}}>
			{navConfig.map((item) => (
				<NavItem key={item.title} item={item}/>
			))}
		</Stack>
	);

	const renderUpgrade = (
		<Box sx={{height: 80}}>
			<Stack >
				<Button
					sx={{height: 80, borderRadius: 0}}
					fullWidth={true}
					onClick={userLogout}
					variant="contained"
					color="inherit"
				>
					Logout
				</Button>
			</Stack>
		</Box>
	);

	const renderContent = (
		<Scrollbar
			sx={{
				height: 1,
				'& .simplebar-content': {
					height: 1,
					display: 'flex',
					flexDirection: 'column',
					background: wesee.gray100,
				},
			}}
		>
			<Grid
				container
				height="100%"
				direction="column"
				justifyContent="space-between"
				alignItems="stretch"
			>
				<Grid
					container
					direction="row"
					justifyContent="flex-start"
					alignItems="flex-end"
					style={{
						padding: '43px 0 43px 21px',
					}}
				>
					<span
						style={{
							fontWeight: 700,
							fontSize: 24,
							color: '#ffffff',
						}}
					>
						위씨
					</span>
					<div style={{
						textAlign: 'center',
						color: wesee.brand50,
						fontSize: 14,
						fontWeight: 700,
						wordWrap: 'break-word',
						paddingBottom: 5,
						paddingLeft: 5
					}}>Admin
					</div>
				</Grid>

				{/*{renderAccount}*/}

				{renderMenu}

				<Box sx={{flexGrow: 1}}/>

				{renderUpgrade}
			</Grid>
		</Scrollbar>
	);

	return (
		<Box
			sx={{
				flexShrink: {lg: 0},
				width: {lg: NAV.WIDTH},
			}}
		>
		{upLg ? (
				<Box
					sx={{
						height: 1,
						position: 'fixed',
						width: NAV.WIDTH,
						borderRight: (theme) => `dashed 1px ${theme.palette.divider}`,
					}}
				>
					{renderContent}
				</Box>
			) : (
				<Drawer
					open={openNav}
					onClose={onCloseNav}
					PaperProps={{
						sx: {
							width: NAV.WIDTH,
							background: wesee.gray100,
						},
					}}
				>
					{renderContent}
				</Drawer>
			)}
		</Box>
	);
}

function NavItem({item}: { item: { path: string, icon?: any, title: string } }) {
	const pathname = usePathname();

	const active = item.path === pathname;

	return (
		<ListItemButton
			component={RouterLink}
			href={item.path}
			sx={{
				minHeight: 44,
				borderRadius: 0.75,
				typography: 'h6',
				color: wesee.gray50,
				textTransform: 'capitalize',
				fontWeight: 'fontWeightMedium',
				...(active && {
					color: '#ffffff',
					fontWeight: 'fontWeightSemiBold',
					bgcolor: (theme) => alpha(wesee.brand50, 0.4),
				}),
			}}
		>
			{
				item.icon && (
					<Box component="span" sx={{width: 24, height: 24, mr: 2}}>
						{item.icon}
					</Box>

				)
			}
			<Box component="span">{item.title} </Box>
		</ListItemButton>
	);
};