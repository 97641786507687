import {useSelector} from "react-redux";
import {RootState} from "../store";
import {useCallback} from "react";
import {TypeOption, TypeOptionItem} from "../types/TypeCommon";
import {get, find} from "lodash";

export default function useOptions() {
	const options = useSelector((state: RootState) => state.commonStore.options);

	const optionName = useCallback((key: string, id: string): string => {
		const data = options[key as keyof TypeOption];
		const name = find(data, (item) => item.id === id);
		if (name) return name.desc;
		return '-';
	}, [options]);

	const typeOptionsList = useCallback((key: string): TypeOptionItem[] => {
		const data = get(options, key);
		if (!data) return [];

		return data;
	}, [options])

	return {
		options,
		optionName,
		typeOptionsList
	}
}