import React from 'react';
import './globla.css';
import { initializeApp } from "firebase/app";

import ThemeProvider from "./theme";
import Router from './routes/sections';
import {useScrollToTop} from './hooks/useScrollToTop';
import Progress from './components/progress';


const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_AP_KEY,
  authDomain: "tma-app-f387c.firebaseapp.com",
  databaseURL: "https://tma-app-f387c-default-rtdb.firebaseio.com",
  projectId: "tma-app-f387c",
  storageBucket: "tma-app-f387c.appspot.com",
  messagingSenderId: "935360510589",
  appId: "1:935360510589:web:4dbc59bce69655f8268f14",
  measurementId: "G-8FHH45X1CH"
}

initializeApp(firebaseConfig)

function App() {
  useScrollToTop();
  return (
    <ThemeProvider>
      <Progress />
      <Router />
    </ThemeProvider>
  );
}

export default App;
