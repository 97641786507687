import React, {useCallback, useState} from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Stack from "@mui/material/Stack";
import Scrollbar from "../../commons/scrollbar/Scrollbar";
import {FormControl, InputLabel, OutlinedInput} from "@mui/material";
import {fileUpload} from "../../../lib/file";
import Button from "@mui/material/Button";
import ImageViewDrawer from "../ImageViewDrawer";
import ImageItem from "../ImageItem";
import {useInput, useRouter} from "../../../hooks";
import {ContentRepository} from "../../../lib/firebaseRepository";
import BackButton from "../../commons/backButton";
import ImageRegButtons from "../imageRegButtons";
import dayjs from "dayjs";
import useProgress from "../../../hooks/useProgress";


function NewContainer() {
	const { push } = useRouter();
	const { handleProgress } = useProgress();
	const [thumbnail, setThumbnail] = useState<{
		file: File | null, fileList: FileList | null, url: string
	}>({
		file: null,fileList: null,  url: ''
	});
	const [contentImg, setContentImg] = useState<{
		file: File | null, fileList: FileList | null, url: string
	}>({
		file: null,fileList: null,  url: ''
	});
	const [newsTitle, onChangeNewsTitle] = useInput("");
	const [newsContents, onChangeContents] = useInput("");
	const [thumbnailDetOpen, setThumbnailDetOpen] = useState<boolean>(false);
	const [preview, setPreview] = useState<any>(null);
	const NOW_DATE = dayjs(new Date()).format('YYYY. MM. DD.');

	const handleFileUpload = useCallback(async (e: React.ChangeEvent<HTMLInputElement>, type: 't' | 'c') => {
		if (e.target.files !== null && e.target.files.length > 0) {

			if (type === 't') {
				setThumbnail({
					file: e.target.files[0],
					fileList: e.target.files,
					url: ''
				});
			} else {
				setContentImg({
					file: e.target.files[0],
					fileList: e.target.files,
					url: ''
				});
			}

		}
	}, []);

	const handleThumbnailOnOff = (open: boolean, type?: 't' | 'c') => {

		if (type && open) {
			if (type === 't' && thumbnail.file) {
				preView(thumbnail.file)
			} else if (type === 'c' && contentImg.file) {
				preView(contentImg.file)
			}
		}
		setThumbnailDetOpen(open);
	};

	const preView = useCallback((file: File) => {
		const reader = new FileReader();
		reader.onload = (re: any) => {
			setPreview(re.target.result);
		}
		reader.readAsDataURL(file);
	}, []);

	const handleImageDel = useCallback((e: any, type: 't' | 'c') => {
		e.stopPropagation();
		let dom: any = null;
		const del = {file: null, fileList: null, url: ''};
		if (type === 't') {
			dom = document.getElementById('thumbnailImg');
			setThumbnail(del);
		} else {
			dom = document.getElementById('contentImg');
			setContentImg(del);
		}
		if (dom) dom.value = "";
	}, []);

	const handleRegBtn = useCallback(async () => {
		if (!thumbnail.file) {
			alert("썸네일 이미지을 등록하세요.");
			return;
		}

		if (!contentImg.file) {
			alert("메인 이미지를 등록하세요.");
			return;
		}

		if (!newsTitle) {
			alert("제목을 입력하세요.");
			return;
		}

		if (!newsContents) {
			alert("설명을 입력하세요.");
			return;
		}

		try {
			handleProgress({isShow: true});
			let fileUrl = {
				thumbnailImg: '',
				contentImg: '',
			}
			if (thumbnail.fileList) {
				const thumbnailImgResp = await fileUpload(thumbnail.fileList);
				fileUrl.thumbnailImg = thumbnailImgResp.url;
			}
			if (contentImg.fileList) {
				const contentImgResp = await fileUpload(contentImg.fileList);
				fileUrl.contentImg = contentImgResp.url;
			}

			await new ContentRepository().saveContent({
				type: 'ggul',
				reporter: '',
				title: newsTitle,
				description: newsContents,
				mainImg: fileUrl.contentImg,
				thumbnailImage: fileUrl.thumbnailImg,
				regDate: NOW_DATE
			});
			handleProgress({isShow: false});
			push('/news');

		} catch (error) {
			handleProgress({isShow: false});
			console.log("##### handleRegBtn error: ", error);
		}
	}, [thumbnail, contentImg, newsContents, newsTitle]);

	return (
		<>
			<BackButton />
			<Card>
				{/*<CardHeader title={title} subheader={subheader}/>*/}
				<Scrollbar>
					<Stack
						direction="column"
						justifyContent="flex-start"
						alignItems="stretch"
						spacing={2} sx={{p: 3, pr: 3}}
					>
						<ImageRegButtons handleFileUpload={handleFileUpload}/>
						{
							thumbnail.file != null && (
								<ImageItem
									type="t"
									name={thumbnail.file.name}
									date={NOW_DATE}
									handleThumbnailOnOff={handleThumbnailOnOff}
									handleImageDel={handleImageDel}
								/>
							)
						}
						{
							contentImg.file !== null && (
								<ImageItem
									type="c"
									name={contentImg.file.name}
									date={NOW_DATE}
									handleThumbnailOnOff={handleThumbnailOnOff}
									handleImageDel={handleImageDel}
								/>
							)
						}
						<Stack
							spacing={3}
							style={{paddingTop: 0}}
						>
							<FormControl>
								<InputLabel htmlFor="component-error">제목</InputLabel>
								<OutlinedInput
									label="제목"
									onChange={onChangeNewsTitle}
								/>
							</FormControl>
						</Stack>
						<Stack
							spacing={3}
							style={{paddingTop: 0}}
						>
							<FormControl>
								<InputLabel htmlFor="component-error">설명</InputLabel>
								<OutlinedInput
									id="standard-textarea"
									label="설명"
									placeholder="Placeholder"
									multiline
									minRows="4"
									onChange={onChangeContents}
								/>
							</FormControl>
						</Stack>
					</Stack>
					<Stack
						direction="row"
						justifyContent="flex-end"
						alignItems="flex-start"
						spacing={2}
						sx={{
							p: 3
						}}
					>
						<Button
							variant="contained"
							sx={{
								width: 150,
								height: 50,
								fontSize: 16
							}}
							onClick={handleRegBtn}
						>
							꿀팁뉴스 등록
						</Button>
					</Stack>
				</Scrollbar>
			</Card>
			<ImageViewDrawer
			 handleThumbnailOnOff={handleThumbnailOnOff}
			 thumbnailDetOpen={thumbnailDetOpen}
			 preview={preview}
			/>
		</>
	);
}

export default NewContainer;