import React from 'react';
import IconButton from "@mui/material/IconButton";

type Props = {
	id: string;
	handleFileUpload(e: React.ChangeEvent<HTMLInputElement>): void;
}
function FileAddIcon({ id, handleFileUpload }: Props) {
	return (
		<IconButton
			style={{
				backgroundColor: '#1877F2',
				color: '#ffffff',
				padding: 5,
				fontSize: '1.125rem',
				width: 24,
				height: 24,
			}}
			component="label"
		>
			<svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true"
			     role="img" className="component-iconify MuiBox-root css-1t9pz9x iconify iconify--mingcute" width="1em"
			     height="1em" viewBox="0 0 24 24">
				<g fill="none">
					<path
						d="M24 0v24H0V0zM12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035c-.01-.004-.019-.001-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427c-.002-.01-.009-.017-.017-.018m.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093c.012.004.023 0 .029-.008l.004-.014l-.034-.614c-.003-.012-.01-.02-.02-.022m-.715.002a.023.023 0 0 0-.027.006l-.006.014l-.034.614c0 .012.007.02.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01z"></path>
					<path fill="currentColor"
					      d="M11 20a1 1 0 1 0 2 0v-7h7a1 1 0 1 0 0-2h-7V4a1 1 0 1 0-2 0v7H4a1 1 0 1 0 0 2h7z"></path>
				</g>
			</svg>
			<input
				style={{display: 'none'}}
				id={id}
				name="upload-photo"
				type="file"
				onChange={handleFileUpload}
			/>
		</IconButton>
	);
}

export default FileAddIcon;