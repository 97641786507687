import {useMemo} from 'react';
import {useNavigate} from 'react-router-dom';

// ----------------------------------------------------------------------
export default function useRouter() {
	const navigate = useNavigate();

	return useMemo(
		() => ({
			back: () => navigate(-1),
			forward: () => navigate(1),
			reload: () => window.location.reload(),
			push: (href: any, state?: any) => navigate(href, {state}),
			replace: (href: any) => navigate(href, {replace: true}),
		}),
		[navigate]
	);
}
