import React from "react";
import Box from '@mui/material/Box';
import { useResponsive } from '../../hooks/useReponsive';

import { NAV, HEADER } from './configLayout';


type Props = {
	children: React.JSX.Element;
	sx?: any;
}

const SPACING = 8;

function Main({ children, sx, ...other }: Props) {
	const lgUp = useResponsive({query: 'up', start: 'lg'});
	return (
		<Box
			component="main"
			sx={{
				flexGrow: 1,
				minHeight: 1,
				display: 'flex',
				flexDirection: 'column',
				py: `${HEADER.H_MOBILE + SPACING}px`,
				...(lgUp && {
					px: 2,
					py: `${HEADER.H_DESKTOP + SPACING}px`,
					width: `calc(100% - ${NAV.WIDTH}px)`,
				}),
				...sx,
			}}
			{...other}
		>
			{children}
		</Box>
	);
}

export default Main;