import React, {useCallback, useEffect, useState} from 'react';
import BackButton from "../../commons/backButton";
import Card from "@mui/material/Card";
import Scrollbar from "../../commons/scrollbar/Scrollbar";
import ImageRegButtons from "../imageRegButtons";
import Stack from "@mui/material/Stack";
import dayjs from "dayjs";
import {useLocation} from "react-router-dom";
import {ContentItem} from "../../../types/TypeFirebaseContent";
import ImageItem from "../ImageItem";
import ImageViewDrawer from "../ImageViewDrawer";
import {useInput, useRouter} from "../../../hooks";
import {FormControl, InputLabel, OutlinedInput} from "@mui/material";
import Button from "@mui/material/Button";
import {fileUpload} from "../../../lib/file";
import {ContentRepository} from "../../../lib/firebaseRepository";
import SnackbarMessage from "../../commons/SnackbarMessage";
import useProgress from "../../../hooks/useProgress";

type TypeLocation = {
	state: ContentItem;
}

function NewsDetail() {

	const NOW_DATE = dayjs(new Date()).format('YYYY. MM. DD.');

	const { state } = useLocation() as TypeLocation;
	const { handleProgress } = useProgress();
	const { back } = useRouter();

	const [alertOpen, setAlertOpen] = useState<boolean>(false);
	const [preview, setPreview] = useState<any>(null);
	const [thumbnailDetOpen, setThumbnailDetOpen] = useState<boolean>(false);
	const [newsTitle, onChangeNewsTitle] = useInput(state.title);
	const [newsContents, onChangeContents] = useInput(state.description);
	const [thumbnail, setThumbnail] = useState<{
		file: File | null, fileList: FileList | null, url: string
	}>({
		file: null,fileList: null,  url: ''
	});
	const [contentImg, setContentImg] = useState<{
		file: File | null, fileList: FileList | null, url: string
	}>({
		file: null,fileList: null,  url: ''
	});

	useEffect(() => {
		console.log(state);
	}, []);

	const preView = useCallback((file: File) => {
		const reader = new FileReader();
		reader.onload = (re: any) => {
			setPreview(re.target.result);
		}
		reader.readAsDataURL(file);
	}, []);

	const handleFileUpload = useCallback(async (e: React.ChangeEvent<HTMLInputElement>, type: 't' | 'c') => {
		if (e.target.files !== null && e.target.files.length > 0) {
			if (type === 't') {
				setThumbnail({
					file: e.target.files[0],
					fileList: e.target.files,
					url: ''
				});
			} else {
				setContentImg({
					file: e.target.files[0],
					fileList: e.target.files,
					url: ''
				});
			}

		}
	}, []);

	const handleThumbnailOnOff = (open: boolean, type?: 't' | 'c') => {
		setPreview("");
		if (type && open) {
			if (type === 't') {
				if (thumbnail.file) preView(thumbnail.file)
				else setPreview(state.thumbnailImage);
			} else if (type === 'c') {
				if (contentImg.file) preView(contentImg.file)
				else setPreview(state.mainImg);
			}
		}
		setThumbnailDetOpen(open);
	};

	const handleImageDel = useCallback((e: any, type: 't' | 'c') => {
		e.stopPropagation();
		let dom: any = null;
		const del = {file: null, fileList: null, url: ''};
		if (type === 't') {
			dom = document.getElementById('thumbnailImg');
			setThumbnail(del);
		} else {
			dom = document.getElementById('contentImg');
			// setContentImg(del);
		}
		if (dom) dom.value = "";
	}, []);

	const handleModBtn = async () => {
		if (!state?.id) return;
		if (!newsTitle) {
			alert("제목을 입력하세요.");
			return;
		}

		if (!newsContents) {
			alert("설명을 입력하세요.");
			return;
		}

		try {
			let fileUrl = {
				thumbnailImg: state.thumbnailImage,
				contentImg: state.mainImg,
			}
			if (thumbnail.fileList) {
				const thumbnailImgResp = await fileUpload(thumbnail.fileList);
				fileUrl.thumbnailImg = thumbnailImgResp.url;
			}
			if (contentImg.fileList) {
				const contentImgResp = await fileUpload(contentImg.fileList);
				fileUrl.contentImg = contentImgResp.url;
			}

			handleProgress({isShow: true});

			await new ContentRepository().updateContent(state.id,{
				type: 'ggul',
				reporter: '',
				title: newsTitle,
				description: newsContents,
				mainImg: fileUrl.contentImg,
				thumbnailImage: fileUrl.thumbnailImg,
				regDate: NOW_DATE
			});

			setAlertOpen(true);
			handleProgress({isShow: false});
		} catch (error) {
			handleProgress({isShow: false});
			console.log("##### handleModBtn error: ", error);
		}
	};

	const handleDelBtn = useCallback(async () => {
		if (!state?.id) return;
		handleProgress({isShow: true});
		const isConfirm = window.confirm("삭제 하시겠습니까?");
		if (isConfirm) {
			await new ContentRepository().deleteContent(state.id);
			handleProgress({isShow: false});
			back();
		}
	}, [state]);

	return (
		<>
			<SnackbarMessage
				alertOpen={alertOpen}
				handleClose={setAlertOpen}
				message="수정 되었습니다."
			/>
			<BackButton />
			<Card>
				<Scrollbar>
					<Stack
						direction="column"
						justifyContent="flex-start"
						alignItems="stretch"
						spacing={2} sx={{p: 3, pr: 3}}
					>
						<ImageRegButtons handleFileUpload={handleFileUpload}/>
						{
							(state.thumbnailImage || thumbnail.file) && (
								<ImageItem
									type="t"
									name={thumbnail.file ? thumbnail.file.name : state.thumbnailImage}
									date={thumbnail.file ? NOW_DATE : state.regDate!}
									handleThumbnailOnOff={handleThumbnailOnOff}
									handleImageDel={handleImageDel}
								/>
							)
						}
						{
							(state.mainImg || contentImg.file) && (
								<ImageItem
									type="c"
									name={contentImg.file ? contentImg.file.name : state.mainImg}
									date={contentImg.file ? NOW_DATE : state.regDate!}
									handleThumbnailOnOff={handleThumbnailOnOff}
									handleImageDel={handleImageDel}
								/>
							)
						}
						<Stack
							spacing={3}
							style={{paddingTop: 0}}
						>
							<FormControl>
								<InputLabel htmlFor="component-error">제목</InputLabel>
								<OutlinedInput
									label="제목"
									onChange={onChangeNewsTitle}
									defaultValue={newsTitle}
								/>
							</FormControl>
						</Stack>
						<Stack
							spacing={3}
							style={{paddingTop: 0}}
						>
							<FormControl>
								<InputLabel htmlFor="component-error">설명</InputLabel>
								<OutlinedInput
									id="standard-textarea"
									label="설명"
									placeholder="Placeholder"
									multiline
									minRows="4"
									defaultValue={newsContents}
									onChange={onChangeContents}
								/>
							</FormControl>
						</Stack>
					</Stack>
					<Stack
						direction="row"
						justifyContent="flex-end"
						alignItems="flex-start"
						spacing={2}
						sx={{
							p: 3
						}}
					>
						<Button
							variant="contained"
							sx={{
								width: 150,
								height: 50,
								fontSize: 16
							}}
							onClick={handleModBtn}
						>
							꿀팁뉴스 수정
						</Button>
						<Button
							variant="contained"
							color={"error"}
							sx={{
								width: 100,
								height: 50,
								fontSize: 16

							}}
							onClick={handleDelBtn}
						>
							삭제
						</Button>
					</Stack>
				</Scrollbar>
			</Card>
			<ImageViewDrawer
				handleThumbnailOnOff={handleThumbnailOnOff}
				thumbnailDetOpen={thumbnailDetOpen}
				preview={preview}
			/>
		</>
	);
}

export default NewsDetail;