import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../store";
import {useCallback} from "react";
import {TypeMemberModItem} from "../types/TypeMember";
import {setAllMemberModData, setMemberModData, setMemberModDataMod, setMemberModInit} from "../store/memberStore";
import {memberModAPI} from "../lib/api/userAPI";
import {TypeAPICode} from "../types/TypeCommon";

export default function useMember() {
	const dispatch = useDispatch();
	const memberModData = useSelector((state: RootState) => state.memberStore.memberModData);

	const handleMemberModData = useCallback((data: TypeMemberModItem) => {
		dispatch(setMemberModData({data}));
	}, [dispatch]);

	const handleMemberModDataMod = useCallback((data: TypeMemberModItem) => {
		dispatch(setMemberModDataMod({data}));
	}, [dispatch]);

	const handleAllMemberModData = useCallback((data: TypeMemberModItem[]) => {
		dispatch(setAllMemberModData({data}));

	}, [dispatch]);

	const handleMemberModInit = useCallback(() => {
		dispatch(setMemberModInit());
	}, [dispatch]);

	const memberDataModAPICall = useCallback(async (): Promise<boolean> => {
		try {
			const resp: TypeAPICode = await memberModAPI({
				reviews: memberModData,
			});

			if (resp.status_code !== 200) throw resp;

			return true;
		} catch(e) {
			console.log('##### memberDataModAPICall error: ', e);
			return false;
		}
	}, [memberModData]);

	return {
		memberModData,
		handleMemberModData,
		handleMemberModDataMod,
		handleAllMemberModData,
		handleMemberModInit,
		memberDataModAPICall
	}
}