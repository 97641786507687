import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage"
import { v4 as uuidv4 } from 'uuid';

export const fileUpload = async (file: FileList): Promise<{ url: string }> => {
	if (file.length === 0) throw new Error("Unexpected file")

	const selectedFile = file.item(0)
	const getExtension = (filename: string) => {
		return filename.split(".").pop()
	}

	// const ext = selectedFile.name.split('\.')
	if (selectedFile !== null) {
		const ext = getExtension(selectedFile.name)
		const filePath = `/files/${uuidv4()}.${ext}`
		const storage = getStorage()
		const storageRef = ref(storage, filePath)
		const result = await uploadBytes(storageRef, selectedFile)
		return {
			url: await getDownloadURL(result.ref),
		}
	} else {
		throw Error("file not selected")
	}
}
