import {useCallback} from "react";
import {useDispatch} from "react-redux";

import {loginAPI, loginInfo} from "../lib/api/loginAPI";
import {TypeUserLoginReq, TypeUserLoginResp} from "../types/TypeUser";
import {setAuthInfo, setIsAuth} from "../store/userStore";
import useRouter from "./useRouter";
import {TypeAPICode, TypeOptionResp} from "../types/TypeCommon";
import {optionsAPI} from "../lib/api/commonAPI";
import {setOptions} from "../store/commonStore";

export default function  useLogin() {
	const dispatch = useDispatch();
	const { push } = useRouter();
	const token: string | null = window.localStorage.getItem('access_token');

	const userLoginAPICall: (data: TypeUserLoginReq) => Promise<any> = useCallback(async (data: TypeUserLoginReq) => {
		try {
			const resp: TypeUserLoginResp = await loginAPI(data);
			if (resp?.status_code !== 200 || !resp?.data) {
				throw resp;
			}

			const options: TypeOptionResp = await optionsAPI();

			if (options?.status_code === 200 && options?.data) {
				dispatch(setOptions({options: options.data}));
			} else {
				throw resp;
			}

			const { access_token, refresh_token } = resp.data;

			window.localStorage.setItem('access_token', access_token);
			window.localStorage.setItem('tma_email', data.email);

			dispatch(setAuthInfo({
				auth: {
					accessToken: access_token,
					refreshToken: refresh_token
				},
			}));

			return resp.data;

		} catch (error) {
			window.localStorage.removeItem('access_token');
			return error;
		}
	}, []);

	const userLogout = useCallback(() => {
		window.localStorage.removeItem('access_token');
		window.localStorage.removeItem('tma_email');
		dispatch(setIsAuth(false));
		push('/login');
	}, []);

	const userLoginInfo = useCallback(async () => {
		try {
			const resp: TypeAPICode = await loginInfo();
			if (resp?.status_code !== 200) {
				userLogout();
				return;
			}

			const options: TypeOptionResp = await optionsAPI();

			if (options?.status_code === 200 && options?.data) {
				dispatch(setOptions({options: options.data}));
			} else {
				throw resp;
			}

			dispatch(setIsAuth(true));

		} catch (error) {
			console.log("##### userLoginInfo error: ", error);
		}
	}, []);

	return {
		token: token ? token : null,
		userLoginAPICall,
		userLoginInfo,
		userLogout
	}
}