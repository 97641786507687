import { configureStore } from '@reduxjs/toolkit';
import userStore from './userStore';
import commonStore from './commonStore';
import memberStore from './memberStore';

export const store = configureStore({
	reducer: {
		userStore,
		commonStore,
		memberStore,
	},
	devTools: process.env.REACT_APP_ENV !== 'production'
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
