import React, {useCallback, useEffect, useState} from 'react';
import Stack from "@mui/material/Stack";
import Iconify from "../../commons/iconify";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Scrollbar from "../../commons/scrollbar/Scrollbar";
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TableSortLabel
} from "@mui/material";
import {TypeNewsListHeader} from "../../../types/TypeNews";
import {ContentRepository} from "../../../lib/firebaseRepository";
import {ContentItem} from "../../../types/TypeFirebaseContent";
import Avatar from "@mui/material/Avatar";
import {useRouter} from "../../../hooks";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import IconButton from "@mui/material/IconButton";
import SnackbarMessage from "../../commons/SnackbarMessage";

function NewsList() {
	const [alertOpen, setAlertOpen] = useState<boolean>(false);
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [list, setList] = useState<ContentItem[]>([]);
	const { push } = useRouter();


	useEffect(() => {
		new ContentRepository().getContentList().then((result: ContentItem[]) => {
			console.log("##### : ", result);
			setList(result);
			// setContent(result)
			// setTotalPage(Math.floor(result.length / 10) + 1)
		});
	}, []);

	const HEADER_LABLE: TypeNewsListHeader[] = [
		{ id: 'id', label: '컨텐츠 ID', width: '250px' },
		{ id: 'image', label: '이미지', width: '300px' },
		{ id: 'contents', label: '내용', align: 'left' },
	];

	const handlePage = useCallback((path: string, item?: ContentItem) => {
		push(path, item);
	}, []);

	const handleCopyUrl = useCallback((e: any, id?: string) => {
		e.stopPropagation();
		if (!id) return;
		navigator.clipboard
			.writeText(`ggul-news.com/${id}`)
			.then(() => {
				setAlertOpen(true);
			})
	}, []);

	return (
		// <Container>
		<>
			<SnackbarMessage
				alertOpen={alertOpen}
				handleClose={setAlertOpen}
				message="클립보드에 저장되었습니다."
			/>
			<Stack direction="row" alignItems="center" justifyContent="flex-end" mb={5}>
				{/*<Typography variant="h4">News List</Typography>*/}
				<Button
					variant="contained"
					color="inherit"
					startIcon={<Iconify icon="eva:plus-fill" />}
					onClick={() => handlePage('/news/new')}
				>
					New News
				</Button>
			</Stack>
			<Card>
				<Scrollbar>
					<TableContainer sx={{ overflow: 'unset' }}>
						<Table sx={{ minWidth: 800 }}>
							<TableHead>
								<TableRow>
									{
										HEADER_LABLE.map((headCell: TypeNewsListHeader) => (
											<TableCell
												key={headCell.id}
												align={headCell.align || 'left'}
												sx={{ width: headCell.width, minWidth: headCell.minWidth }}
											>
												<TableSortLabel
													hideSortIcon
												>
													{headCell.label}
												</TableSortLabel>
											</TableCell>
										))
									}
								</TableRow>
							</TableHead>
							<TableBody>
								{
									list
										.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
										.map((row: ContentItem) => (
											<TableRow key={row.id} hover onClick={() => handlePage('/news/detail', row)}>
												<TableCell align={"center"}>
													{row.id}
													<IconButton
														aria-label="delete"
														onClick={(e) => handleCopyUrl(e, row.id)}
													>
														<ContentCopyIcon />
													</IconButton>
												</TableCell>
												<TableCell align={"center"}>
													<Stack
														direction="row"
														justifyContent="flex-start"
														alignItems="center"
														spacing={2}
													>
														<Avatar
															alt={row.id}
															src={row.thumbnailImage}
															variant="rounded"
															sx={{ width: 100, height: 100 }}
														/>
														<Avatar
															alt={row.id}
															src={row.mainImg}
															variant="rounded"
															sx={{ width: 100, height: 100 }}
														/>
													</Stack>
												</TableCell>
												<TableCell>
													{row.title}<br/>
													{row.description}
												</TableCell>
											</TableRow>
										))
								}
							</TableBody>
						</Table>
					</TableContainer>
				</Scrollbar>
			</Card>
		</>
		// </Container>
	);
}

export default NewsList;