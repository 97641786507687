import React, {forwardRef} from 'react';
import {Icon, IconifyIcon} from '@iconify/react';
import Box from "@mui/material/Box";

type Props = {
	icon: IconifyIcon | string;
	width?: any;
	sx?: any;
}

const Iconify = forwardRef(({ icon, width = 20, sx, ...other }: Props, ref) => (
	<Box
		ref={ref}
		component={Icon}
		className="component-iconify"
		icon={icon}
		sx={{ width, height: width ? width : 20, ...sx }}
		{...other}
	/>
));

export default Iconify;