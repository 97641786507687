import React from 'react';
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import {FileAddIcon} from "../commons/icon";

type Props = {
	handleFileUpload(e: React.ChangeEvent<HTMLInputElement>, type: 't' | 'c'): void;
}
function ImageRegButtons({handleFileUpload}: Props) {
	return (
		<Stack
			direction="row"
			justifyContent="flex-start"
			alignItems="center"
			spacing={3}
			// sx={{p: 2, pr: 2}}
		>
			<Stack
				direction="row"
				justifyContent="flex-start"
				alignItems="center"
				spacing={1}
			>
				<Typography variant="h6">
					썸네일 이미지
				</Typography>
				<FileAddIcon
					id="thumbnailImg"
					handleFileUpload={(e) => handleFileUpload(e, 't')}
				/>
			</Stack>
			<Stack
				direction="row"
				justifyContent="flex-start"
				alignItems="center"
				spacing={1}
			>
				<Typography variant="h6">
					컨텐츠 이미지
				</Typography>
				<FileAddIcon
					id="contentImg"
					handleFileUpload={(e) => handleFileUpload(e, 'c')}
				/>
			</Stack>
		</Stack>
	);
}

export default ImageRegButtons;