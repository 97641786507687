import {TypeMemberModItem} from "../types/TypeMember";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {cloneDeep, findIndex} from "lodash";

type TypeStore = {
	memberModData: TypeMemberModItem[]
}

const initialState: TypeStore = {
	memberModData: []
}

const reducers = {
	setMemberModData: (state: TypeStore, action: PayloadAction<{data: TypeMemberModItem}>): void => {
		const { data } = action.payload;
		const temp = cloneDeep(state.memberModData);
		const findIdx = findIndex(temp, (v) => v.member_id === data.member_id);
		console.log({data, findIdx});
		if(findIdx === -1) {
			temp.push(action.payload.data);
		} else {
			temp.splice(findIdx, 1);
		}

		state.memberModData = temp;
	},

	setMemberModDataMod: (state: TypeStore, action: PayloadAction<{data: TypeMemberModItem}>): void => {
		const { data } = action.payload;
		const temp = cloneDeep(state.memberModData);
		const findIdx = findIndex(temp, (v) => v.member_id === data.member_id);

		if (findIdx > -1) {
			temp[findIdx] = data;
		} else {
			temp.push(data);
		}

		state.memberModData = temp;
	},

	setAllMemberModData: (state: TypeStore, action: PayloadAction<{ data: TypeMemberModItem[] }>): void => {
		state.memberModData = action.payload.data;
	},

	setMemberModInit: (state: TypeStore): void => {
		state.memberModData = [];
	}
}

const useStore = createSlice({
	name: 'memberStore',
	initialState,
	reducers,
});

export default useStore.reducer;
export const {
	setMemberModData,
	setMemberModInit,
	setMemberModDataMod,
	setAllMemberModData
} = useStore.actions;