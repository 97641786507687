import React, {useEffect} from 'react';

import {useLogin, useRouter} from '../hooks';
import {useSelector} from "react-redux";
import {RootState} from "../store";

type Props = {
	children: React.JSX.Element;
}
function AuthContainers({ children }: Props) {
	const {token, userLoginInfo} = useLogin();
	const { push } = useRouter();
	const isAuth = useSelector((state: RootState) => state.userStore.isAuth);

	useEffect(() => {
		if (!isAuth && token) {
			userLoginInfo().then(r => {});
		}
		else if (!isAuth) push('/login');
	}, [isAuth, token]);

	return isAuth ? children : <></>;
}

export default AuthContainers;