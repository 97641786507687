import client from "./client";
import {TypeMemberListReq, TypeMemberModReq} from "../../types/TypeMember";

export const memberListAPI = (data: TypeMemberListReq) => (
	client({
		url: '/v1/members/search',
		params: data,
	})
);

export const memberListTotalCountAPI = (data?: TypeMemberListReq) => (
	client({
		url: '/v1/members/search/total-count',
		params: data
	})
)

export const memberModAPI = (data: TypeMemberModReq) => (
	client({
		url: '/v1/members/review',
		data: data,
		method: 'post',
		loading: true,
	})
)