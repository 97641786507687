import React, {useMemo} from 'react';

import CssBaseline from '@mui/material/CssBaseline';
import {createTheme, ThemeProvider as MUIThemeProvider} from '@mui/material/styles';

import {palette} from './palette';
import {shadows} from './shadows';
import {overrides} from './overrides';
import {typography} from './typography';
import {customShadows} from './custom-shadows';

type Props = {
	children: any
}
export default function ThemeProvider({children}: Props) {
	const memoizedValue: any = useMemo(
		() => ({
			palette: palette(),
			typography,
			shadows: shadows(),
			customShadows: customShadows(),
			shape: {borderRadius: 8},
			breakpoints: {
				values: {
					xs: 0,
					sm: 600,
					md: 900,
					lg: 1200,
					xl: 1536,
					xxl: 1920, // 새로운 브레이크포인트 추가
				},
			},
		}),
		[]
	);

	const theme: any = createTheme(memoizedValue);

	theme.components = overrides(theme);

	return (
		<MUIThemeProvider theme={theme}>
			<CssBaseline/>
			{children}
		</MUIThemeProvider>
	);
}