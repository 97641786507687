import {useMemo} from "react";
import {TypeProgress} from "../types/TypeCommon";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../store";
import {setProgress} from "../store/commonStore";

export default function useProgress() {
	const dispatch = useDispatch();
	const progress: TypeProgress = useSelector((state: RootState) => state.commonStore.progress);

	return useMemo(() => ({
		progressStore: progress,
		handleProgress: (data: TypeProgress) => {
			dispatch(setProgress({progress: data}));
		}
	}),[progress]);
}