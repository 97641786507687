import {TypeOption, TypeOptionItem, TypeProgress} from "../types/TypeCommon";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";

type TypeStore = {
	progress: TypeProgress;
	options: TypeOption;
};

const initialState: TypeStore = {
	progress: {
		isDim: true,
		isShow: false,
	},
	options: {
		genders: [],
		comm_cycle: [],
		meeting_cycle: [],
		meeting_time: [],
		mobile_provider: [],
		native_person: [],
		member_review_status_type: [],
		member_type: [],
		salary_type: [],
	}
};

const reducers = {
	setProgress: (state: TypeStore, action: PayloadAction<{progress: TypeProgress}>): void => {
		const { isShow, isDim = true } = action.payload.progress;
		state.progress.isShow = isShow;
		state.progress.isDim = isDim;
	},
	setOptions: (state: TypeStore, action: PayloadAction<{options: TypeOption}>): void => {
		state.options = action.payload.options;
	},
}

const useStore = createSlice({
	name: 'tmaCommonStore',
	initialState,
	reducers
});

export default useStore.reducer;
export const {
	setProgress,
	setOptions
} = useStore.actions;