import React from 'react';
import {Snackbar} from "@mui/material";

type Props = {
	alertOpen: boolean;
	handleClose(onOff: boolean): void;
	message: string;
	autoHideDuration?: number;
}
function SnackbarMessage({alertOpen, handleClose, message, autoHideDuration = 1500}: Props) {
	return (
		<Snackbar
			anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
			open={alertOpen}
			onClose={() => handleClose(false)}
			message={message}
			autoHideDuration={autoHideDuration}
			sx={{
				'.MuiSnackbarContent-root': {justifyContent: 'center'}
			}}
		/>
	);
}

export default SnackbarMessage;
