import React from 'react';
import { CircularProgress } from '@mui/material';
import useProgress from "../hooks/useProgress";

function Progress() {
	const { progressStore } = useProgress();

	if (!progressStore.isShow) return <></>;

	return (
		<div
			className="sweet-loading"
			style={{
				width: '100%',
				height: '100%',
				position: 'fixed',
				justifyContent: 'center',
				alignItems: 'center',
				display: 'flex',
				background: !progressStore.isDim ? 'none' : `rgb(239, 239, 239, 0.5)`,
				zIndex: 99999
			}}
		>
			<CircularProgress />
		</div>

	);
}

export default Progress;