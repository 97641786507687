import React, {lazy, Suspense} from 'react';
import {Outlet, useRoutes} from 'react-router-dom';

import DashboardLayout from "../layouts/dashboard";
import AuthContainers from '../components/AuthContainers';
import NewsList from "../components/news/list/newsList";
import NewContainer from "../components/news/reg/newContainer";
import NewsDetail from "../components/news/det/newsDetail";

export const MainPage = lazy(() => import('../page/mainPage'));
export const UserPage = lazy(() => import('../page/userPage'));
export const ProviderPage = lazy(() => import('../page/providerPage'));
export const News = lazy(() => import('../page/news'));
export const LoginPage = lazy(() => import('../page/loginPage'));

function Router() {

	return useRoutes(
		[
			{
				element: (
					<AuthContainers>
						<DashboardLayout>
							<Suspense>
								<Outlet/>
							</Suspense>
						</DashboardLayout>
					</AuthContainers>
				),
				children: [
					{element: <MainPage/>, index: true},
					{path: 'user', element: <UserPage/>},
					{path: 'produce', element: <MainPage/>},
					{path: 'store', element: <MainPage/>},
					{path: 'push', element: <MainPage/>},
					{
						element: (
							<News>
								<Suspense>
									<Outlet/>
								</Suspense>
							</News>
						),
						children: [
							{path: 'news', element: <NewsList/>},
							{path: 'news/new', element: <NewContainer/>},
							{path: 'news/detail', element: <NewsDetail />}
						]
					}
				]
			},
			{
				path: 'login',
				element: <LoginPage />
			}
		]
	);
}

export default Router;