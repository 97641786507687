import React from 'react';

import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { useTheme } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';

import { useResponsive } from '../../hooks/useReponsive';
import { bgBlur } from '../../theme/css';

import Iconify from '../../components/commons/iconify';
import { NAV, HEADER } from './configLayout';

type Props = {
	onOpenNav(): void;
}

function Header({ onOpenNav }: Props) {
	const theme = useTheme();

	const lgUp = useResponsive({query: 'up', start: 'lg'});

	const renderContent = (
		<>
			{!lgUp && (
				<IconButton onClick={onOpenNav} sx={{ mr: 1 }}>
					<Iconify icon="eva:menu-2-fill" />
				</IconButton>
			)}

			<Box sx={{ flexGrow: 1 }} />
		</>
	);

	return (
		<AppBar
			sx={{
				...bgBlur({
					color: theme.palette.background.default,
				}) as any,
				boxShadow: 'none',
				height: HEADER.H_MOBILE,
				zIndex: theme.zIndex.appBar + 1,
				transition: theme.transitions.create(['height'], {
					duration: theme.transitions.duration.shorter,
				}),
				...(lgUp && {
					width: `calc(100% - ${NAV.WIDTH + 1}px)`,
					height: HEADER.H_DESKTOP,
				}),
			}}
		>
			<Toolbar
				sx={{
					height: 1,
					px: { lg: 5 },
				}}
			>
				{renderContent}
			</Toolbar>
		</AppBar>
	);
}

export default Header;