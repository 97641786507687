import axios, {AxiosError, AxiosResponse} from 'axios';

type props = {
	url: string;
	baseURL?: string;
	data?: any;
	params?: any;
	header?: any;
	timeout?: number;
	loading?: boolean;
	file?: boolean;
	isAuth?: boolean;
	errorMsg?: boolean;
	withCredentials?: boolean;
	isCancelToken?: boolean;
	type?: 'excel' | 'file',
	method?: 'get' | 'post' | 'delete' | 'put';
}

const httpAxios = axios.create({
	baseURL: process.env.REACT_APP_API_URL
});

/**
 * 공통 api 통신 모듈
 * @param {String} url : api url
 * @param {String} method: get, post, delete, put
 * @param {String} type: excel 또는 file 일때  responseType 데이터 유형 arraybuffer 지정
 * @param {Object} params: api url요청과 함께 전송 될 URL 매개 변수입니다
 * @param {Object} data: api url요청에 body로 전송할 데이터입니다.
 * @param {Object} header: 사용자 정의 해더
 * @param {boolean} loading: 로딩바 생성 유무
 * @param {boolean} isAuth: 헤더 토큰 인증 여부
 * @param {boolean} file: true일 때 Content-type을 multipart/form-data
 * @param {number} timeout: 요청시간 초과되기까지 시간(밀리초) 지정
 *
 */

export enum HttpStatusCode {
	None = 0,
	Ok = 200,
	BadRequest = 400,
	Unauthorized = 401,
	Forbidden = 403,
	NotFound = 404,
}

const client = async ({
	url,
	type,
	data,
	params,
	header,
	timeout,
	baseURL,
	isAuth = true,
	loading = false,
	errorMsg = true,
	file = false,
	isCancelToken = false,
	method = 'get',
	withCredentials = false,
	}: props) => {
	let headers = header ? header : {};
	if (file) headers["Content-type"] = "multipart/form-data";
	if (loading) {}
	if (isAuth) {
		const token = window.localStorage.getItem("access_token");
		if (!token) {
			console.log('##### not token');
		}

		headers = Object.assign({}, header);
		headers["Authorization"] = `Bearer ${token}`;
	}

	if (baseURL) httpAxios.defaults.baseURL = baseURL;
	else httpAxios.defaults.baseURL = process.env.REACT_APP_API_URL;

	return httpAxios({
		url,
		method,
		data,
		params,
		headers,
		timeout: timeout ? timeout : 100000,
		responseType: type === 'excel' || type === 'file' ? 'arraybuffer' : undefined,
		withCredentials,
	})
		.then((response: AxiosResponse) => {
			// if ()
			return {
				message: '',
				status_code: response.status,
				data: response.data,
			}
		})
		.catch((error: AxiosError) => {
			const { response } = error;
			console.log(error);
			return {
				message: "",
				status_code: error.status,
			}
		})
}

export default client;