import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {TypeUserState} from "../types/TypeUser";

type TypeStore = {
	auth: TypeUserState;
	isAuth: boolean;
}

const initialState: TypeStore = {
	auth: {
		accessToken: '',
		refreshToken: ''
	},
	isAuth: false
};

const reducers = {
	setAuthInfo: (state: TypeStore, action: PayloadAction<{auth: TypeUserState}>): void => {
		state.auth = action.payload.auth;
		state.isAuth = true;
	},
	setIsAuth: (state: TypeStore, action: PayloadAction<boolean>): void => {
		state.isAuth = action.payload;
	}
};

const useStore = createSlice({
	name: 'tmaUserStore',
	initialState,
	reducers
});
export default  useStore.reducer;
export const { setAuthInfo, setIsAuth } = useStore.actions;