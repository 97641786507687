import React from 'react';
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import {SwipeableDrawer} from "@mui/material";

type Props = {
	preview: any;
	thumbnailDetOpen: boolean;
	handleThumbnailOnOff(isOnOf: boolean): void;
}

function ImageViewDrawer({ preview, thumbnailDetOpen, handleThumbnailOnOff }: Props) {
	return (
		<SwipeableDrawer
			anchor="right"
			open={thumbnailDetOpen}
			onClose={() => handleThumbnailOnOff(false)}
			onOpen={() => handleThumbnailOnOff(true)}
			sx={{
				'.MuiBackdrop-root': {backgroundColor: 'transparent'}
			}}
		>
			<Stack
				direction="column"
				justifyContent="space-between"
				alignItems="flex-start"
				spacing={2}
				style={{
					height:' 100%'
				}}
				onKeyDown={() => handleThumbnailOnOff(false)}
				onClick={() => handleThumbnailOnOff(false)}
			>

				<Box
					sx={{
						width: 320
					}}
				>
					<Stack
						spacing={2}
						sx={{p: 3, pr: 3}}
					>
						<Typography variant="h5">썸네일</Typography>
					</Stack>
					<img
						height={350}
						width="100%"
						src={preview ? preview : "https://api-prod-minimal-v510.vercel.app/assets/images/cover/cover_2.jpg"}
						alt=""
					/>
					<Stack
						spacing={2}
						sx={{p: 3, pr: 3}}
					>
						<Typography variant="h6">image file name</Typography>
					</Stack>
					<Stack
						spacing={2}
						sx={{pt: 3, pl: 3, pr: 0}}
					>
						<Typography variant="h6">Properties</Typography>
					</Stack>
					<Stack
						direction="row"
						justifyContent="flex-start"
						alignItems="flex-start"
						spacing={10}
						sx={{p: 3, pr: 0}}
					>
						<Typography variant="subtitle1">Size</Typography>
						<span>ddd</span>
					</Stack>
				</Box>
				<Button
					variant="contained"
					color="error"
					fullWidth={true}
					sx={{
						height: 50,
						borderRadius: 0
					}}
				>
					삭제
				</Button>
			</Stack>
		</SwipeableDrawer>
	);
}

export default ImageViewDrawer;